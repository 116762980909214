<template>
   <div class="col-12 px-0 mb-1" ref="pedido">
      <div class="card mb-0" :class="index % 2 == 1 ? 'invert' : ''">
         <div class="card-body p-12">
            <div class="row align-items-center">
               <div class="col-md text-center text-sm-start cursor-pointer" @click="visualizar">
                  <div class="row align-items-center">
                     <div class="col-12">
                        <h1 class="font-15 mb-0 limitador-1 text-uppercase">{{ pedido.nome == null || String(pedido.nome).trim().length == 0 ? 'Sem nome' : pedido.nome }}</h1>
                        <p class="font-10 mb-1 limitador-1 text-secondary">
                           <span><i class="far fa-hashtag font-9 me-1"></i>{{ pedido.id }}</span>
                           <span class="ms-3"><i class="far fa-exchange font-9 me-1"></i>{{ pedido.status == null ? 'Sem status' : pedido.status }}</span>
                           <span class="ms-3"><i class="far fa-truck font-8 me-1"></i>{{ pedido.cpfCnpjFornecedor == null ? 'Sem fornecedor' : pedido.cpfCnpjFornecedor }}</span>
                        </p>
                     </div>
                     <div class="col-sm-6 col-xl-4">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-store color-theme font-9 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Loja:</strong>
                           <span> {{ pedido.nomeLoja == null || String(pedido.nomeLoja).trim().length == 0 ? 'Sem loja' : pedido.nomeLoja }}</span>
                           <small v-if="pedido.numeroLoja" class="mx-1">|</small>
                           <span v-if="pedido.numeroLoja"><i class="far fa-tag font-8 me-1"></i>{{ pedido.numeroLoja }}</span>
                        </p>
                     </div>
                     <div class="col-sm-6 col-xl-4 d-none d-sm-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-calendar color-theme font-10 me-1"></i><strong class="subtitle me-1 d-none d-lg-inline"> Data:</strong>
                           <span>{{ pedido.data == null ? 'Sem data' : String(pedido.data.dayOfMonth).padStart(2, '0') +'/'+ String(pedido.data.monthValue).padStart(2, '0') +'/'+ String(pedido.data.year) +' '+ String(pedido.data.hour).padStart(2, '0') +':'+ String(pedido.data.minute).padStart(2, '0') }}</span>
                        </p>
                     </div>
                     <div class="col-xl-4 d-none d-xl-block">
                        <p class="font-12 mb-0 limitador-1">
                           <i class="far fa-dollar-sign color-theme font-10 me-1"></i><strong class="subtitle me-1"> Valor total: </strong>
                           <span><small>R$ </small>{{ pedido.valorTotal == null ? '0,00' : parseFloat(pedido.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
                        </p>
                     </div>
                  </div>
               </div>
               <div class="w-max-content d-flex align-items-center font-16 text-center mx-auto mx-md-0 mt-2 mt-md-0">
                  <div class="btn-icone color-theme">
                     <span @click="visualizar"><i class="fal fa-plus"></i><small>Visualizar</small></span>
                  </div>
                  <div class="btn-icone text-red" v-if="dadosUsuario.usuarioPermissoes.includes('Compras | Editar pedidos')">
                     <span @click="remover"><i class="far fa-trash"></i><small>Excluir</small></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>


<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Pedido',
   props: ['pedido', 'index'],
   computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest
      })
   },
   methods: {
      visualizar : function () {
         this.$emit('visualizar', this.pedido)
      },
      remover : function () {
         Swal.fire({
            icon: 'warning',
            title: 'Excluir pedido?',
            text: 'O pedido será excluído, essa ação é irreversível.',
            showCancelButton: true,
            confirmButtonText: 'Confirmar',
            cancelButtonText: `Cancelar`
				
         }).then((result) => {
            if (result.isConfirmed) {
					this.$store.dispatch('alternarTelaCarregamento', true)
		
					this.$axios({
						method: 'post',
						url: this.urlRest +'compras/deletePedido',
                  params: {
                     id: this.pedido.id  
                  }
					}).then(() => {
						this.$refs.pedido.style.display = 'none'
		
						this.$toast.fire({
							icon: 'success',
							title: 'Pedido excluído!'
						});
					}).catch((error) => {
						if (error.response != undefined) {
							if (error.response.status == 408) {
								this.$store.dispatch('deslogar')
		
								this.$toast.fire({
									icon: 'warning',
									title: 'Sessão expirada!'
								});
							} else {
								this.$toast.fire({
									icon: 'error',
									title: 'Erro: ' + error.response.status
								});
							}
						}
					}).finally(() => {
						this.$store.dispatch('alternarTelaCarregamento', false)
					});
				}
			})
      }
   }
}

</script>